import React from 'react';
import { Button, Container, Dropdown, Form, Input, Grid, Header, Segment } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { CSVReader } from 'react-papaparse';

import OriginAddress from './OriginAddress';

import { getDate, ignoreAutoComplete } from '../functions/base';

function isDisabled(edit) {
  return (
    edit.dimensions &&
    edit.dimensions.length &&
    edit.dimensions.width &&
    edit.dimensions.height &&
    edit.fileData.length &&
    edit.locationId &&
    edit.shippingCode &&
    edit.weight &&
    edit.shipDate) ? false : true
}

function BatchCreateForm(props) {
  const shippingServicePlaceHolder = (!props.shipVias && !props.shipVias.length) ? 'Please Select From Address' : 'Shipping Service'
  const disabled = isDisabled(props.edit)
  const columnWidth = 4

  return <Form>
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          {props.errorMessages &&
            <Segment>
              <Container textAlign="center">
                <Header style={{ backgroundColor: 'red' }} sub>Errors</Header>
                <span>{props.errorMessages}</span>
              </Container>
            </Segment>
          }
          <Header>
            Choose CSV File
          </Header>
          <CSVReader
            ref={props.fileInputRef}
            onFileLoad={props.handleOnFileLoad}
            onError={props.handleOnError}
            noClick
            noDrag
          >
            {({ file }) => {
              const content = (file) ? `${file.name} (${props.humanFileSize(file.size)})` : 'Choose File'
              return <Button
                  color='orange'
                  content={content}
                  labelPosition="left"
                  icon="file"
                  onClick={props.handleOpenDialog}
                />
            }
          }
          </CSVReader>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          <Header>
            From Address
          </Header>
          <OriginAddress
            header={null}
            locationId={props.locationId}
            placeholder='From Address'
            setEdit={(locationId) => props.setEdit({ ...props.edit, locationId: locationId })}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          <Header>
            Shipping Service
          </Header>
          <Dropdown
            fluid
            onChange={(e, data) => props.setEdit({ ...props.edit, shippingCode: data.value })}
            onFocus={ignoreAutoComplete}
            options={props.shipVias}
            placeholder={shippingServicePlaceHolder}
            search
            selection
            value={props.edit.shippingCode}
            width='six'
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          <Header>
            Dimensions
          </Header>
          <Form.Group>
            <Form.Input
              label='Length'
              onChange={(e) => props.setEdit({ ...props.edit, dimensions: { ...props.edit.dimensions, length: e.target.value }})}
              onFocus={ignoreAutoComplete}
              placeholder='Length'
              value={props.edit.dimensions.length}
              width='six'
            />
            <Form.Input
              label='Width'
              onChange={(e) => props.setEdit({ ...props.edit, dimensions: { ...props.edit.dimensions, width: e.target.value }})}
              onFocus={ignoreAutoComplete}
              placeholder='Width'
              value={props.edit.dimensions.width}
              width='six'
            />
            <Form.Input
              label='Height'
              onChange={(e) => props.setEdit({ ...props.edit, dimensions: { ...props.edit.dimensions, height: e.target.value }})}
              onFocus={ignoreAutoComplete}
              placeholder='Height'
              value={props.edit.dimensions.height}
              width='six'
            />
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          <Header>
            Weight
          </Header>
          <Input
            fluid
            onChange={(e) => props.setEdit({ ...props.edit, weight: e.target.value})}
            onFocus={ignoreAutoComplete}
            value={props.edit.weight}
            width='six'
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          <Header>
            Shipping Date
          </Header>
          <DateInput
            animation=''
            closable={true}
            dateFormat="MM-DD-YYYY"
            fluid
            minDate={getDate()}
            onChange={(e, data) => props.setEdit({ ...props.edit, shipDate: data.value})}
            popupPosition="top right"
            value={props.edit.shipDate}
          />
          <Button
            color='blue'
            disabled={disabled}
            loading={props.processing}
            onClick={(e) => {e.preventDefault(); props.runBatch();}}
          >Create Batch</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
}

export default BatchCreateForm;