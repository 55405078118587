import React from 'react';
import { Button } from 'semantic-ui-react';

function ShippingButtons(props) {
  return <>
    <Button
      color='blue'
      disabled={props.loading}
      loading={props.loading}
      onClick={() => props.webServiceRate()}
    >
      Rate
    </Button>
    <Button
      color='orange'
      disabled={props.loading}
      loading={props.loading}
      onClick={() => props.webServiceShip()}
    >
      Ship
    </Button>
    <Button
      color='yellow'
      disabled={props.loading}
      loading={props.loading}
      onClick={() => props.webServiceBusinessRules()}
    >
      Business Rules
    </Button>
    <Button
     color='blue'
     disabled={props.loading}
     loading={props.loading}
     onClick={() => props.webServiceReadScale()}
    >
      Read Scale
    </Button>
  </>
}

export default ShippingButtons;