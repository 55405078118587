import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button, Container, Form, Header, Segment } from 'semantic-ui-react';

import { login } from '../api/authentication-api';

import { ignoreAutoComplete, useAuth } from '../functions/base';
import Branding from './Branding';

function Login(props) {
  const [edit, setEdit] = useState({clientId: '', username: '', password: ''})
  const [errorMessages, setErrorMessages] = useState('')
  const [loading, setLoading] = useState(false)
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth()

  useEffect(() => {
    if (auth.token) {
      history.push('/')
    }
  }, [auth.token, history])

  function webServiceLogin() {
    setLoading(true)
    setErrorMessages('')
    function callback(data) {
      setLoading(false)
      auth.signin(data.token)
      let { from } = location.state || {from: { pathname: '/' }};
      history.replace(from)
    }

    const errorCallback = (error) => {
      setLoading(false)
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }

    login(edit.clientId, edit.username, edit.password, callback, errorCallback);
  }

  return <Form style={{margin: 'auto'}}>
    <Container style={{width: 500}}>
      <Branding />
      {errorMessages &&
        <Segment>
          <Container textAlign="center">
            <Header style={{ backgroundColor: 'red' }} sub>Errors</Header>
            <span>{errorMessages}</span>
          </Container>
        </Segment>
      }
      <Form.Input
        fluid
        label='Client Id'
        onChange={(e) => setEdit({ ...edit, clientId: e.target.value })}
        placeholder='Client Id'
        value={edit.clientId}
      />
      <Form.Input
        fluid
        label='Username'
        onChange={(e) => setEdit({ ...edit, username: e.target.value })}
        placeholder='Username'
        value={edit.username}
      />
      <Form.Input
        fluid
        label='Password'
        onChange={(e) => setEdit({ ...edit, password: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Password'
        type='password'
        value={edit.password}
      />
      <Button
        onClick={() => webServiceLogin()}
        loading={loading}
      >
        Login
      </Button>
    </Container>
  </Form>
}

export default Login;