import React, { useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { ignoreAutoComplete } from '../functions/base';
import AccessorialsScreen from './AccessorialsScreen';

function PackageScreen(props) {
  const [openAccessorials, setOpenAccessorials] = useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)

  let boxesDropDown = [{key: '', text: '', value: ''}]
  for (const box of props.boxes) {
    boxesDropDown.push({
      key: box.id,
      text: box.id,
      value: box.id
    })
  }
  let accessorials = props.package.accessorials
  accessorials['residential'] = props.residential
  accessorials['billingMethod'] = props.billingMethod

  return <>
    <Form.Group widths='equal'>
      <Form.Dropdown
        fluid
        label='Box Id'
        options={boxesDropDown}
        onChange={(_, data) => {
          const box = props.boxes.find(b => b.id === data.value);
          if (!box)
            return;
          const updatedDimensions = {
            ...props.package.dimensions,
            length: box.length,
            width: box.width,
            height: box.height
          };
          props.editPackage(props.package.id, {
            ...props.package,
            dimensions: updatedDimensions
          })
          props.setBoxId(data.value)
        }}
        onFocus={ignoreAutoComplete}
        search
        selection
        value={props.boxId}
      />
      <Form.Input
        fluid
        label='Length'
        placeholder='Length'
        onChange={(e) => {
          const updatedDimensions = { ...props.package.dimensions, length: e.target.value };
          props.editPackage(props.package.id, {
            ...props.package,
            dimensions: updatedDimensions
          })
        }}
        onFocus={ignoreAutoComplete}
        value={props.package.dimensions.length}
      />
      <Form.Input
        fluid
        label='Width'
        placeholder='Width'
        onChange={(e) => {
          const updatedDimensions = { ...props.package.dimensions, width: e.target.value };
          props.editPackage(props.package.id, {
            ...props.package,
            dimensions: updatedDimensions
          })
        }}
        onFocus={ignoreAutoComplete}
        value={props.package.dimensions.width}
      />
      <Form.Input
        fluid
        label='Height'
        placeholder='Height'
        onChange={(e) => {
          const updatedDimensions = { ...props.package.dimensions, height: e.target.value };
          props.editPackage(props.package.id, {
            ...props.package,
            dimensions: updatedDimensions
          })
        }}
        onFocus={ignoreAutoComplete}
        value={props.package.dimensions.height}
      />
    </Form.Group>
    <Form.Field>
      <label>Weight</label>
      <Form.Input
        onChange={(e) => {
          const updatedWeight = { ...props.package.weight, value: e.target.value };
          props.editPackage(props.package.id, {
            ...props.package,
            weight: updatedWeight
          })
        }}
        onFocus={ignoreAutoComplete}
        value={props.package.weight.value}
      />
    </Form.Field>
    <Form.Group>
      <Modal
        onClose={() => setOpenAccessorials(false)}
        onOpen={() => setOpenAccessorials(true)}
        open={openAccessorials}
        trigger={<Button color='blue'>Accessorials</Button>}
      >
        <Modal.Content>
          <AccessorialsScreen
            accessorials={props.package.accessorials}
            allAccessorials={props.allAccessorials}
            setEdit={props.editPackageAccessorial}
            packageId={props.package.id}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='blue'
            content='Done'
            onClick={() => setOpenAccessorials(false)}
          />
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setOpenConfirmDelete(false)}
        onOpen={() => setOpenConfirmDelete(true)}
        open={openConfirmDelete}
        trigger={<Button color='red'><Icon name='remove' />Package</Button>}
      >
        <Modal.Content>
          Are you sure you want to delete this package?
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='blue'
            content='Cancel'
            onClick={() => setOpenConfirmDelete(false)}
          />
          <Button
            color='red'
            content='Confirm'
            onClick={() => {
              props.deletePackage(props.package.id)
              setOpenConfirmDelete(false)
            }}
          />
        </Modal.Actions>
      </Modal>
    </Form.Group>
  </>
}

export default PackageScreen;