import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const routingCodesApiUrl = `${config.endpoints.routingCodesApiUrl}/api/v1/RoutingCodes`;

export async function getParcelRoutingCodesByLocationId(token, locationId, callback, errorCallback) {
  if (locationId) {
    axios.get(`${routingCodesApiUrl}/GetParcelByLocationId/${locationId}`, getConfig(token))
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      errorCallback(err)
    })
  } else {
    callback([])
  }
}