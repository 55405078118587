import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const accessorialsApiUrl = `${config.endpoints.accessorialsApiUrl}/api/v1/Accessorials`;

export async function getAccessorialsByCode(token, code, callback, errorCallback) {
  axios.get(`${accessorialsApiUrl}?code=${code}`, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}