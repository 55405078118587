import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { getPickTickets } from '../api/pickTickets-api';
import { useAuth } from '../functions/base';

function Home(props) {
  const [pickTickets, setPickTickets] = useState([])
  const auth = useAuth()
  let history = useHistory();

  useEffect(() => {
    const callback = (data) => {
      setPickTickets(data)
    }
    const errorCallback = (error) => console.error(error);
    getPickTickets(auth.token, callback, errorCallback);
  }, [auth, setPickTickets])

  const createBatchRedirect = () => {
    history.push('/batch')
  }

  return <>
      <Button
        color='orange'
        onClick={() => createBatchRedirect()}
      >Create Batch</Button>
      <ul>
        {pickTickets.map(pickTicket => {
          return <li key={pickTicket.id}>
              <Link to={`/shipping/${pickTicket.id}`}>Pick Ticket {pickTicket.customerPickTicketNumber}</Link>
            </li>
        })}
      </ul>
    </>
}

export default Home;