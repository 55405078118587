import React, { useEffect, useState } from 'react';
import { Dropdown, Header } from 'semantic-ui-react';

import { ignoreAutoComplete, useAuth } from '../functions/base';

import { getAllClientLocations } from '../api/locations-api';

function OriginAddress(props) {
  const [locations, setLocations] = useState([]);
  let auth = useAuth()

  useEffect(() => {
    const callback = (data) => {
      let locations = []
      for (const rawLocation of data) {
        const location = {
          key: rawLocation.id,
          text: `${rawLocation.description} (${rawLocation.clientLocationId})`,
          value: rawLocation.id
        };
        locations.push(location);
      }
      setLocations(locations);
    }
    const errorCallback = (error) => console.error(error);
    getAllClientLocations(auth.token, callback, errorCallback);
  }, [auth.token])

  const placeholder = props.placeholder || 'Location'

  return <div>
      {props.header &&
        <Header as='h4'>
          {props.header}
        </Header>
      }
      <Dropdown
        fluid
        onChange={(e, data) => props.setEdit(data.value)}
        onFocus={ignoreAutoComplete}
        options={locations}
        placeholder={placeholder}
        search
        selection
        value={props.locationId}
      />
    </div>
}

export default OriginAddress;