export const config = {
  endpoints: {
    accessorialsApiUrl:
      process?.env?.REACT_APP_ACCESSORIALS_API || "https://localhost:5001",
    addressesApiUrl:
      process?.env?.REACT_APP_ADDRESSES_API || "https://localhost:5001",
    authenticationApiUrl:
      process?.env?.REACT_APP_AUTHENTICATION_API || "https://localhost:5001",
    boxesApiUrl:
      process?.env?.REACT_APP_BOXES_API || "https://localhost:5001",
    countriesApiUrl:
      process?.env?.REACT_APP_COUNTRIES_API || "https://localhost:5001",
    locationsApiUrl:
      process?.env?.REACT_APP_LOCATIONS_API || "https://localhost:5001",
    messagesApiUrl:
      process?.env?.REACT_APP_MESSAGES_API || "https://localhost:5001",
    pickTicketsApiUrl:
      process?.env?.REACT_APP_PICK_TICKETS_API || "https://localhost:5001",
    ratingApiUrl:
      process?.env?.REACT_APP_RATING_API || "https://localhost:5001",
    routingCodesApiUrl:
      process?.env?.REACT_APP_RATING_CODES_API || "https://localhost:5001",
    rulesMapperApiUrl:
      process?.env?.REACT_APP_RULES_MAPPER_API || "https://localhost:5001",
    shippingApiUrl:
      process?.env?.REACT_APP_SHIPPING_API || "https://localhost:5001",
    statesApiUrl:
      process?.env?.REACT_APP_STATES_API || "https://localhost:5001",
    truckLoadAuctionApiUrl:
      process?.env?.REACT_APP_TRUCK_LOAD_AUCTION_API || "https://localhost:5001"
  }
};