import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const addressesApiUrl = `${config.endpoints.addressesApiUrl}/api/v1/Addresses`;

export async function getAddressesByLocationId(token, locationId, callback, errorCallback) {
  axios.get(`${addressesApiUrl}/GetAddressesByLocationId/${locationId}`, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}