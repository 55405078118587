import React from 'react';
import { Button, Header, Icon, Tab } from 'semantic-ui-react';

import PackageScreen from './PackageScreen';

function PackagesScreen(props) {
  let panes = []
  for (const parcel of props.packages) {
    panes.push({
      menuItem: `${parcel.id}`,
      render: () => <Tab.Pane>
        <PackageScreen
          allAccessorials={props.allAccessorials}
          billingMethod={props.billingMethod}
          boxes={props.boxes}
          boxId={props.boxId}
          deletePackage={props.deletePackage}
          editPackage={props.editPackage}
          editPackageAccessorial={props.editPackageAccessorial}
          package={parcel}
          residential={props.residential}
          setBoxId={props.setBoxId}
        />
      </Tab.Pane>
    });
  }
  return <>
    <Header as='h4'>
      <Button
        color='blue'
        onClick={() => props.addPackage()}
      >
        <Icon name='add'/>Package
      </Button>
    </Header>
    {props.packages.length === 0 &&
      'No Packages, please add one'
    }
    {props.packages.length > 0 &&
      <Tab
        activeIndex={props.activePackageIndex}
        onTabChange={(_, data) => {
          props.setActivePackageIndex(data.activeIndex)
          props.setBoxId(null)
        }}
        panes={panes}
      />
    }
  </>
}

export default PackagesScreen;