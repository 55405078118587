import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const pickTicketsApiUrl = `${config.endpoints.pickTicketsApiUrl}/api/v1/PickTickets`;

export async function getPickTickets(token, callback, errorCallback) {
  axios.get(`${pickTicketsApiUrl}`, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}

export async function getPickTicketById(token, pickTicketId, callback, errorCallback) {
  axios.get(`${pickTicketsApiUrl}/GetById/${pickTicketId}`, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}