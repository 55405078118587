import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const statesApiUrl = `${config.endpoints.statesApiUrl}/api/v1/States`;

export async function getStatesByCountryCode(token, countryCode, callback, errorCallback) {
  if (countryCode) {
    axios.get(`${statesApiUrl}/GetByCountryCode/${countryCode}`, getConfig(token))
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      errorCallback(err)
    })
  } else {
    callback([])
  }
}