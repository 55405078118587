import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const boxesApiUrl = `${config.endpoints.boxesApiUrl}/api/v1/Boxes`;

export async function getBoxes(token, callback, errorCallback) {
  axios.get(boxesApiUrl, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}