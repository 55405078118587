import React from 'react';
import { Form } from 'semantic-ui-react';

function LeftScreenFields(props) {
  return <>
    <Form.Dropdown
      fluid
      label="Customer Pick Ticket Number"
      onChange={(_, data) => props.setCustomerPickTicketNumber(data.value)}
      options={props.pickTickets}
      search
      selection
      selectOnNavigation={false}
      value={props.customerPickTicketNumber}
    />
    {props.children}
  </>
}

export default LeftScreenFields;