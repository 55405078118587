import React from 'react';
import { Container, Header, Radio, Segment } from 'semantic-ui-react';

import RateTable from './RateTable';

const styles = {
  inlineItems: {alignItems: "center"},
  noFlexGrowOrShrink: {display: "flex", flex: "0 0"}
}

const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

function hasChargeBesidesTotalCharge(rateResult) {
  if (Number.isFinite(rateResult.baseCharge))
    return true
  if (Number.isFinite(rateResult.fuelCharge))
    return true
  if (Number.isFinite(rateResult.specialServicesCharge))
    return true
  if (Number.isFinite(rateResult.discount))
    return true
  return false;
}

function ShippingAndRatingResults(props) {
  return <Container style={{ minHeight: 387 }}>
    {props.errorMessages &&
      <Segment>
        <Container textAlign="center">
          <Header style={{ backgroundColor: 'red' }} sub>Errors</Header>
          <span>{props.errorMessages}</span>
        </Container>
      </Segment>
    }
    {props.rateResult &&
      <>
        <Segment style={styles.noFlexGrowOrShrink}>
          <Container textAlign="center">
            <Header sub>Total Charge</Header>
            {Number.isFinite(props.rateResult.totalCharge) &&
              <span>{formatter.format(props.rateResult.totalCharge)}</span>
            }
            {!Number.isFinite(props.rateResult.totalCharge) &&
              <span>{props.rateResult.totalCharge}</span>
            }
          </Container>
          <Container textAlign="center">
            <Header sub>Time In Transit</Header>
            <span>
              {props.rateResult.tntDays} {!isNaN(props.rateResult.tntDays) && <span>days</span>}
            </span>
          </Container>
          <Container textAlign="center">
            <Header sub>Shipping Service</Header>
            <span>{props.rateResult.shippingCode}</span>
          </Container>
        </Segment>
        {hasChargeBesidesTotalCharge(props.rateResult) &&
          <RateTable formatter={formatter} result={props.rateResult} />
        }
      </>
    }
    {props.rateShopResult && props.rateShopResult.results &&
      <Segment style={styles.inlineItems}>
        {props.rateShopResult.results.map((result) => (
          <Segment key={result.shippingCode} style={styles.noFlexGrowOrShrink}>
            <Container style={styles.noFlexGrowOrShrink}>
              {!isNaN(result.totalCharge) &&
                <Radio
                  checked={props.shippingCode === result.key}
                  onClick={() => props.editShippingCode(result.key)}
                />
              }
            </Container>
            <Container textAlign="center">
              <Header sub>Total Charge</Header>
              {Number.isFinite(result.totalCharge) &&
                <span>{formatter.format(result.totalCharge)}</span>
              }
              {!Number.isFinite(result.totalCharge) &&
                <span>{result.totalCharge}</span>
              }
            </Container>
            <Container textAlign="center">
              <Header sub>Time In Transit</Header>
              <span>
                {result.tntDays} {!isNaN(result.tntDays) && <span>days</span>}
              </span>
            </Container>
            <Container textAlign="center">
              <Header sub>Shipping Service</Header>
              <span>{result.shippingCode}</span>
            </Container>
          </Segment>
        ))}
      </Segment>
    }
    {props.shipResult &&
      <>
        <Segment style={styles.noFlexGrowOrShrink}>
          <Container textAlign="center">
            <Header sub>Total Charges</Header>
            {Number.isFinite(props.shipResult.totalCharge) &&
              <span>{formatter.format(props.shipResult.totalCharge)}</span>
            }
            {!Number.isFinite(props.shipResult.totalCharge) &&
              <span>{props.shipResult.totalCharge}</span>
            }
          </Container>
          <Container textAlign="center">
            <Header sub>Time In Transit</Header>
            <span>
              {props.shipResult.tntDays} {!isNaN(props.shipResult.tntDays) && <span>days</span>}
            </span>
          </Container>
          <Container textAlign="center">
            <Header sub>Shipping Service</Header>
            <span>{props.shipResult.shippingCode}</span>
          </Container>
          <Container textAlign="center">
            <Header sub>Tracking Number</Header>
            <span>{props.shipResult.trackingNumber}</span>
          </Container>
        </Segment>
        {hasChargeBesidesTotalCharge(props.shipResult) &&
          <RateTable formatter={formatter} result={props.shipResult} />
        }
      </>
    }
    {props.businessRulesResults &&
      <Segment style={styles.noFlexGrowOrShrink}>
        <Container textAlign="center">
          <Header sub>Business Rules Result</Header>
          <span>{props.businessRulesResults.message}</span>
        </Container>
      </Segment>
    }
  </Container>
}

export default ShippingAndRatingResults;