import React, { useEffect, useState } from 'react';

import { Form } from 'semantic-ui-react';

import { getAllCountries } from '../api/countries-api';
import { getStatesByCountryCode } from '../api/states-api';

import { ignoreAutoComplete, useAuth } from '../functions/base';

function BillingAddress(props) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  let auth = useAuth()

  useEffect(() => {
    const callback = (data) => {
      let countries = []
      for (const rawCountry of data) {
        const country = {
          key: rawCountry.code,
          text: rawCountry.name,
          value: rawCountry.code
        };
        countries.push(country);
      }
      setCountries(countries);
    }
    const errorCallback = (error) => console.error(error);
    getAllCountries(auth.token, callback, errorCallback);
  }, [auth.token, setCountries])

  const stateCallback = (data) => {
    let states = []
    for (const rawState of data) {
      const state = {
        key: rawState.code,
        text: rawState.name,
        value: rawState.code
      };
      states.push(state);
    }
    setStates(states);
  }
  const stateErrorCallback = (error) => console.error(error);

  useEffect(() => {
    getStatesByCountryCode(auth.token, props.address.country, stateCallback, stateErrorCallback);
  }, [auth.token, props.address.country])

  function addressItems() {
    let addresses = []
    for (const address of props.addresses) {
      if (address.type === "BILLTO")
        addresses.push({key: address.id, value: address.id, text: address.id})
    }
    return addresses
  }

  function handleSearchChange(e, data) {
    if (!data.value)
      return;
    const address = props.addresses.filter((a) => a.id === data.value && a.type === "BILLTO")[0]
    if (address) {
      let {addressId, ...copy} = address;
      props.setEdit(copy)
    }
  }

  return <>
    <Form.Dropdown
      fluid
      label='Address Lookup'
      onChange={handleSearchChange}
      onFocus={ignoreAutoComplete}
      options={addressItems()}
      placeholder="Address Lookup"
      search
      selection
    />
    <Form.Input
      label='Account Number'
      onFocus={ignoreAutoComplete}
      onChange={(e) => props.setEdit({ ...props.address, account: e.target.value })}
      value={props.address?.account}
    />
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        label='Company'
        onChange={(e) => props.setEdit({ ...props.address, company: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Company'
        value={props.address.company}
      />
      <Form.Input
        fluid
        label='Name'
        onChange={(e) => props.setEdit({ ...props.address, name: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Name'
        value={props.address.name}
      />
    </Form.Group>
    <Form.Field>
      <Form.Input
        fluid
        label='Line 1'
        onChange={(e) => props.setEdit({ ...props.address, line1: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Line 1'
        value={props.address.line1}
      />
    </Form.Field>
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        label='Line 2'
        onChange={(e) => props.setEdit({ ...props.address, line2: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Line 2'
        value={props.address.line2}
      />
      <Form.Input
        fluid
        label='Line 3'
        onChange={(e) => props.setEdit({ ...props.address, line3: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Line 3'
        value={props.address.line3}
      />
    </Form.Group>
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        label='City'
        onChange={(e) => props.setEdit({ ...props.address, city: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='City'
        value={props.address.city}
        width='fifteen'
      />
      <Form.Dropdown
        fluid
        label='State'
        onChange={(e, data) => props.setEdit({ ...props.address, state: data.value })}
        onFocus={ignoreAutoComplete}
        options={states}
        placeholder='State'
        search
        selection
        value={props.address.state}
      />
    </Form.Group>
    <Form.Group>
      <Form.Input
        fluid
        label='Postal Code'
        onChange={(e) => props.setEdit({ ...props.address, postalCode: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Postal Code'
        value={props.address.postalCode}
        width='eight'
      />
      <Form.Dropdown
        fluid
        label='Country'
        onChange={(e, data) => {
          props.setEdit({ ...props.address, country: data.value })
          getStatesByCountryCode(auth.token, data.value, stateCallback, stateErrorCallback)
        }}
        onFocus={ignoreAutoComplete}
        options={countries}
        placeholder='Country'
        search
        selection
        value={props.address.country}
      />
    </Form.Group>
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        label='Email'
        onChange={(e) => props.setEdit({ ...props.address, email: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Email'
        value={props.address.email}
      />
      <Form.Input
        fluid
        label='Phone'
        onChange={(e) => props.setEdit({ ...props.address, phone: e.target.value })}
        onFocus={ignoreAutoComplete}
        placeholder='Phone'
        value={props.address.phone}
        width='eight'
      />
    </Form.Group>
  </>
}

export default BillingAddress;