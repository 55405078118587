import axios from "axios";

export async function readScale(url, body, callback, errorCallback) {
  axios.post(url, body)
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}

export async function printLabel(url, body, callback, errorCallback) {
  axios.post(url, body)
  .then(response => {
    callback(response.data)
  })
  .catch(err => {
    errorCallback(err)
  })
}