import React from 'react';
import { Table } from 'semantic-ui-react';

function RateTable(props) {
  const {formatter, result} = props;
  return <Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Charge</Table.HeaderCell>
        <Table.HeaderCell>Amount</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {result.baseCharge > 0 &&
        <Table.Row>
          <Table.Cell>Base Charge</Table.Cell>
          <Table.Cell>{formatter.format(result.baseCharge)}</Table.Cell>
        </Table.Row>
      }
      {result.fuelCharge > 0 &&
        <Table.Row>
          <Table.Cell>Fuel Charge</Table.Cell>
          <Table.Cell>{formatter.format(result.fuelCharge)}</Table.Cell>
        </Table.Row>
      }
      {result.specialServicesCharge > 0 &&
        <Table.Row>
          <Table.Cell>Special Services Charge</Table.Cell>
          <Table.Cell>{formatter.format(result.specialServicesCharge)}</Table.Cell>
        </Table.Row>
      }
      {result.discount > 0 &&
        <Table.Row>
          <Table.Cell>Discount</Table.Cell>
          <Table.Cell>{formatter.format(result.discount)}</Table.Cell>
        </Table.Row>
      }
    </Table.Body>

    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell>Total Charge</Table.HeaderCell>
        <Table.HeaderCell>{formatter.format(result.totalCharge)}</Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  </Table>
}

export default RateTable;