import React from 'react';
import { Grid, Header, Icon, Progress, Table } from 'semantic-ui-react';


function getTableRow(shippingResult) {
  const trackingNumber = (shippingResult.batchShippedStatus === 'SUCCESS') ? shippingResult.shippingResult?.trackingNumber : shippingResult.batchShippedStatus
  const tntDays = (shippingResult.batchShippedStatus === 'SUCCESS') ? shippingResult.shippingResult.tntDays : shippingResult.batchShippedStatus
  const errorSymbol = (shippingResult.batchShippedStatus === 'ERROR') ? <Icon color='red' name='warning sign' /> : ''

  return <Table.Row key={shippingResult.customerPickTicketNumber}>
    <Table.Cell>{shippingResult.customerPickTicketNumber}</Table.Cell>
    <Table.Cell>{shippingResult.shipToAddress.postalCode}</Table.Cell>
    <Table.Cell>{errorSymbol}{trackingNumber}</Table.Cell>
    <Table.Cell>{errorSymbol}{tntDays}</Table.Cell>
    <Table.Cell>{shippingResult.lineNumber}</Table.Cell>
    <Table.Cell>{shippingResult.errorMessage}</Table.Cell>
  </Table.Row>
}

function resultsCompare(a, b) {
  if (a.batchShippedStatus < b.batchShippedStatus)
    return -1
  if (b.batchShippedStatus < a.batchShippedStatus)
    return 1
  if (a.lineNumber < b.lineNumber)
    return -1
  return 1
}

function getTime(seconds) {
  if (seconds < 60)
    return `${seconds} seconds`
  const minutes = Math.floor(seconds / 60)
  const remainder = seconds % 60
  if (minutes === 1)
    return `${minutes} minute ${remainder} seconds`
  return `${minutes} minutes ${remainder} seconds`
}

function BatchProcessing(props) {
  const batchResults = props.batchResults.sort(resultsCompare)
  const total = batchResults.length
  const done = batchResults.filter(r => r.batchShippedStatus !== 'NOT_SHIPPED').length
  return <Grid centered padded>
      <Grid.Row>
        <Grid.Column width={12}>
          <Progress color='orange' value={done} total={total} progress='ratio' />
          <Header>Total Time: {getTime(props.seconds)}</Header>
          <Table>
            <Table.Header>
              <Table.Row key='tableHeader'>
                <Table.HeaderCell>Pick Ticket Number</Table.HeaderCell>
                <Table.HeaderCell>Postal Code</Table.HeaderCell>
                <Table.HeaderCell>Tracking Number</Table.HeaderCell>
                <Table.HeaderCell>Time In Transit Days</Table.HeaderCell>
                <Table.HeaderCell>Line Number</Table.HeaderCell>
                <Table.HeaderCell>Additional Information</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {batchResults &&
                batchResults.map((record, i) => {
                  return getTableRow(record, null)
                })
              }
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
}

export default BatchProcessing;