import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const countriesApiUrl = `${config.endpoints.countriesApiUrl}/api/v1/Countries`;

export async function getAllCountries(token, callback, errorCallback) {
  axios.get(countriesApiUrl, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}