import React from 'react';

import { Form } from 'semantic-ui-react';

import { ignoreAutoComplete } from '../functions/base';


function getAccessorialInput(accessorial, editAccessorials, setEdit, packageId) {
  const value = editAccessorials[accessorial.key];
  if (accessorial.valueType === "bool") {
    let actualValue = value
    if (typeof actualValue === "string") {
      actualValue = (!actualValue) ? false : true
    }
    return <Form.Checkbox
      checked={actualValue}
      key={accessorial.key}
      label={accessorial.title}
      onChange={(_, data) => setEdit(packageId, accessorial.key, data.checked)}
      toggle
    />
  } else if (accessorial.valueType === "string") {
    let actualValue = (value === null) ? "" : value
    return <Form.Input
        key={accessorial.key}
        label={accessorial.title}
        onChange={(_, data) => setEdit(packageId, accessorial.key, data.value)}
        value={actualValue}
      />
  } else if (accessorial.valueType === "dropdown") {
    return <Form.Dropdown
        fluid
        key={accessorial.key}
        label={accessorial.title}
        onFocus={ignoreAutoComplete}
        onChange={(_, data) => setEdit(packageId, accessorial.key, data.value)}
        options={accessorial.possibleValues.sort((a, b) => (a.value > b.value) ? 1 : -1)}
        placeholder={accessorial.title}
        search
        selection
        value={value}
      />
  }
}

function AccessorialsScreen(props) {
  return <Form>
    {props.allAccessorials.map((accessorial) => {
      const input = getAccessorialInput(accessorial, props.accessorials, props.setEdit, props.packageId)
      return input
    })}
  </Form>
}

export default AccessorialsScreen;