import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Button, Form, Grid, Message } from 'semantic-ui-react';

import { getAuctionBidEmailByHash, updateAuctionBid } from '../api/truckLoadAuction-api';

import { ignoreAutoComplete, useAuth } from '../functions/base';

function AuctionBid(props) {
  const [edit, setEdit] = useState({})
  const [messages, setMessages] = useState({})
  let { hash } = useParams()
  let auth = useAuth()
  let history = useHistory()

  useEffect(() => {
    const callback = (data) => {
      setEdit(data)
    }
    const errorCallback = (error) => {
      history.push('/')
    }
    getAuctionBidEmailByHash(auth.token, hash, callback, errorCallback);
  }, [auth.token, history, hash])

  function acceptBid() {
    const callback = (data) => {
      setMessages({general: 'Bid updated'})
    }
    const errorCallback = (error) => {
      setMessages({general: error})
    }
    const body = {
      specialServiceCharge: edit.auctionBid.specialServiceCharge,
      fuelCharge: edit.auctionBid.fuelCharge,
      baseCharge: edit.auctionBid.baseCharge,
      comment: edit.auctionBid.comment,
      status: "Accepted"
    }
    updateAuctionBid(auth.token, edit.auctionBid.auctionId, hash, body, callback, errorCallback)
  }

  function declineBid() {
    const callback = (data) => {
      const auctionBid = {...edit.auctionBid, status: 'Declined'}
      setEdit({...edit, auctionBid: auctionBid})
      setMessages({general: 'Bid updated'})
    }
    const errorCallback = (error) => {
      setMessages({general: error})
    }
    const body = {
      specialServiceCharge: 0,
      fuelCharge: 0,
      baseCharge: 0,
      comment: "",
      status: "Declined"
    }
    updateAuctionBid(auth.token, edit.auctionBid.auctionId, hash, body, callback, errorCallback)
  }

  if (Object.keys(edit).length === 0)
    return <span>loading...</span>

  const heading = `#${edit.auctionBid.auction.customerTrackingNumber} Auction Bid (${edit.auctionBid.auction.clientId})`
  // const weight = edit.auctionBid.auction.shipmentJson;
  const weight = 2121
  const originAddress = edit.auctionBid.auction.shipmentJson.addresses.find(obj => obj.addressType === 'ORIGIN')
  const shipToAddress = edit.auctionBid.auction.shipmentJson.addresses.find(obj => obj.addressType === 'SHIPTO')
  const from = `${originAddress.city}, ${originAddress.state}. ${originAddress.zip}`
  const to = `${shipToAddress.city}, ${shipToAddress.state}. ${shipToAddress.zip}`
  const palletCount = 21
  const truckTypeSpecialService = edit.auctionBid.auction.shipmentJson.specialServices.find(obj => obj.id === 'TRUCKTYPE')
  const truckType = (truckTypeSpecialService) ? truckTypeSpecialService.value : ''
  // const dockClosingTime = new Date(edit.auctionBid.auction.shipment.dockClosingTime)
  const dockClosingTime = new Date('2021-08-05T23:00:00Z')
  const requiredPickupAccessorials = 'Lift Gate'
  const requiredDeliveryAccessorials = 'None'
  const status = edit.auctionBid.status
  const columnWidth = 4

  return <Form>
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          <strong>{heading}</strong>
          {messages && messages.general &&
            <>
              <Message>
                {messages.general}
              </Message>
            </>
          }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={columnWidth}>
          <p>From: {from}</p>
          <p>To: {to}</p>
          <p>Shipment Weight: {weight.toLocaleString()} lbs</p>
          {/* <p>Number of pallets: {palletCount}</p> */}
          <p>Truck type: {truckType}</p>
          <p>Dock Closing Time: {dockClosingTime.toLocaleString()}</p>
        </Grid.Column>
      </Grid.Row>
      {/* <Grid.Row>
        <Grid.Column width={columnWidth}>
          <p>Required pickup accessorials: {requiredPickupAccessorials}</p>
          <p>Required delivery accessorials: {requiredDeliveryAccessorials}</p>
        </Grid.Column>
      </Grid.Row> */}
      {status === "NoResponse" &&
        <>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              <Button
                color='orange'
                onClick={(e) => {
                  const auctionBid = {...edit.auctionBid, status: 'Accepted'}
                  setEdit({...edit, auctionBid: auctionBid})
                }}
              >
                I want to place bid
              </Button>
              <Button
                color='red'
                floated='right'
                onClick={(e) => {
                  declineBid()
                }}
              >
                I'll pass on this one
              </Button>
            </Grid.Column>
          </Grid.Row>
        </>
      }
      {status === 'Declined' &&
        <>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              <h3>We have marked your bid as declined. Thank you.</h3>
              <p>Or</p>
              <Button
                color='orange'
                onClick={(e) => {
                  const auctionBid = {...edit.auctionBid, status: 'Accepted'}
                  setEdit({...edit, auctionBid: auctionBid})
                }}
              >
                Actually, I want to place a bid
              </Button>
            </Grid.Column>
          </Grid.Row>
        </>
      }
      {status === "Accepted" &&
        <>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              {messages && messages.baseCharge &&
                <Message>
                  {messages.baseCharge}
                </Message>
              }
              <Form.Input
                fluid
                label='Base Charge'
                placeholder='Base Charge'
                onChange={(e) => {
                  const auctionBid = {...edit.auctionBid, baseCharge: e.target.value}
                  setEdit({
                    ...edit,
                    auctionBid: auctionBid
                  })
                }}
                onFocus={ignoreAutoComplete}
                value={edit.auctionBid.baseCharge}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              <Form.Input
                fluid
                label='Fuel Charge'
                placeholder='Fuel Charge'
                onChange={(e) => {
                  const auctionBid = {...edit.auctionBid, fuelCharge: e.target.value}
                  setEdit({
                    ...edit,
                    auctionBid: auctionBid
                  })
                }}
                onFocus={ignoreAutoComplete}
                value={edit.auctionBid.fuelCharge}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              <Form.Input
                fluid
                label='Accessorial Charge'
                placeholder='Accessorial Charge'
                onChange={(e) => {
                  const auctionBid = {...edit.auctionBid, specialServiceCharge: e.target.value}
                  setEdit({
                    ...edit,
                    auctionBid: auctionBid
                  })
                }}
                onFocus={ignoreAutoComplete}
                value={edit.auctionBid.specialServiceCharge}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              <Form.TextArea
                label='Comments'
                placeholder='Comments'
                onChange={(e) => {
                  const auctionBid = {...edit.auctionBid, comment: e.target.value}
                  setEdit({
                    ...edit,
                    auctionBid: auctionBid
                  })
                }}
                onFocus={ignoreAutoComplete}
                value={edit.auctionBid.comment}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              <Button
                color='orange'
                onClick={(e) => {
                  if (edit.auctionBid.baseCharge <= 0) {
                    setMessages({baseCharge: 'Please input a base charge'})
                  } else {
                    acceptBid()
                  }
                }}
              >
                Place my bid
              </Button>
              <Button
                color='red'
                floated='right'
                onClick={(e) => {
                  declineBid()
                }}
              >
                Never mind, I pass
              </Button>
            </Grid.Column>
          </Grid.Row>
        </>
      }
    </Grid>
  </Form>
}

export default AuctionBid;