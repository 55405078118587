import React from 'react';
import { Container, Icon } from 'semantic-ui-react';

function getColor(level) {
  if (level === 'warning')
    return 'yellow'
  else if (level === 'error')
    return 'red'
  else
    return 'green'
  
}

function getIconAlias(level) {
  if (level === 'warning')
    return 'warning'
  else if (level === 'error')
    return 'cancel'
  else
    return 'announcement'
}

function TargetedMessages(props) {
  if (!props.messages || props.messages.length < 1)
    return ''
  return <Container fluid textAlign="center" width='sixteen'>
      {props.messages.map((message, index) => {
        return <p key={index}><Icon color={getColor(message.level)} name={getIconAlias(message.level)} />{message.text}</p>
      })}
    </Container>
}

export default TargetedMessages;