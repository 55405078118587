import React from 'react';
import { Tab } from 'semantic-ui-react';

import BillingAddress from './BillingAddress';
import OriginAddress from './OriginAddress';
import ShippingAddress from './ShippingAddress';

function AddressContainer(props) {
  const panes = [
    {
      menuItem: 'Ship To',
      render: () => <Tab.Pane>
        <ShippingAddress
            address={props.shipToAddress}
            addresses={props.addresses}
            setEdit={props.editShipToAddress}
        />
      </Tab.Pane>
    },
    {
      menuItem: 'Bill To',
      render: () => <Tab.Pane>
        <BillingAddress
          address={props.billToAddress}
          addresses={props.addresses}
          setEdit={props.editBillToAddress} />
      </Tab.Pane>
    },
    {
      menuItem: 'Origin',
      render: () => <Tab.Pane>
        <OriginAddress
          locationId={props.locationId}
          setEdit={props.editOriginAddress} />
      </Tab.Pane>
    },
    // {
    //   menuItem: 'Return',
    //   render: () => <Tab.Pane>
    //     <OriginAddress />
    //   </Tab.Pane>
    // }
  ]
  return <Tab panes={panes} />
}

export default AddressContainer;