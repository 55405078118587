import React, { createRef, useEffect, useState } from 'react';

import BatchCreateForm from './BatchCreateForm';
import BatchProcessing from './BatchProcessing';

import { ship } from '../api/shipping-api';
import { getParcelRoutingCodesByLocationId } from '../api/routingCodes-api';

import { getDate, useAuth } from '../functions/base';

function BatchCreate(props) {
  const [edit, setEdit] = useState({
    dimensions: {
      length: '',
      width: '',
      height: ''
    },
    fileData: [],
    shipDate: getDate(),
    weight: ''
  })
  const [batchResults, setBatchResults] = useState([])
  const [errorMessages, setErrorMessages] = useState('')
  const [finished, setFinished] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [shipVias, setShipVias] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const auth = useAuth()
  let fileInputRef = createRef()

  useEffect(() => {
    let interval = null;
    if (submitted && !finished) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [finished, submitted, seconds])

  useEffect(() => {
    if (!submitted || finished)
      return
    const leftToRun = batchResults.filter(r => r.batchShippedStatus === 'NOT_SHIPPED').length
    if (leftToRun === 0) {
      setFinished(true)
      return
    }
    for (const record of batchResults) {
      if (record.batchShippedStatus !== 'NOT_SHIPPED')
        continue
      function callback(data) {
        const newPickTicket = Object.assign({}, record)
        newPickTicket.batchShippedStatus = 'SUCCESS'
        newPickTicket.shippingResult = data
        let newResults = [...batchResults]
        newResults = newResults.map(r => {
          if (r.lineNumber === newPickTicket.lineNumber)
          {
            return newPickTicket
          }
          return r
        })
        setBatchResults(newResults)
      }
  
      function errorCallback(error) {
        const newPickTicket = Object.assign({}, record)
        newPickTicket.batchShippedStatus = 'ERROR'
        let errorMessage = ''
        if (error.response)
          errorMessage = error.response.data.message
        else
          errorMessage = error.message
        newPickTicket.errorMessage = errorMessage
        let newResults = [...batchResults]
        newResults = newResults.map(r => {
          if (r.lineNumber === newPickTicket.lineNumber)
            return newPickTicket
          return r
        })
        setBatchResults(newResults)
      }
      ship(auth.token, record, callback, errorCallback)
      break
    }
  }, [batchResults, submitted])

  useEffect(() => {
    const callback = (data) => {
      if (!data.shippingCodes)
        return;
      let shipVias = []

      for (const rawShippingCode of data.shippingCodes) {
        const shippingCode = {
          key: rawShippingCode.code,
          text: rawShippingCode.displayName || rawShippingCode.code,
          value: rawShippingCode.code
        };
        shipVias.push(shippingCode);
      }
      setShipVias(shipVias);
    }
    const errorCallback = (error) => {
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }
    getParcelRoutingCodesByLocationId(auth.token, edit.locationId, callback, errorCallback);
  }, [auth.token, edit.locationId])

  function runBatch() {
    setSubmitted(true)
    const newBatchResults = []
    let i = 1
    for (const record of edit.fileData) {
      if (record.data.length !== 11)
        continue
      const pickTicket = {
        customerPickTicketNumber: record.data[0].trim(),
        shipDate: edit.shipDate,
        locationId: edit.locationId,
        billingMethod: 'prepaid',
        shipToAddress: {
          name: record.data[1].trim(),
          line1: record.data[2].trim(),
          line2: record.data[3].trim(),
          line3: record.data[4].trim(),
          city: record.data[5].trim(),
          state: record.data[6].trim(),
          postalCode: record.data[7].trim(),
          country: record.data[8].trim(),
          email: record.data[9].trim(),
          phone: record.data[10].trim()
        },
        packages: [
          {
            id: 1,
            weight: {
              value: edit.weight,
              unit: 'pounds'
            },
            dimensions: {
              unit: 'inches',
              length: edit.dimensions.length,
              width: edit.dimensions.width,
              height: edit.dimensions.height
            }
          }
        ],
        shippingCode: edit.shippingCode,
        batchShippedStatus: 'NOT_SHIPPED',
        errorMessage: '',
        lineNumber: i
      }
      i++
      newBatchResults.push(pickTicket)
    }
    setBatchResults(newBatchResults)
  }

  function handleOpenDialog(e) {
    // Note that the ref is set async, so it might be null at some point
    if (fileInputRef.current) {
      fileInputRef.current.open(e);
    }
  };

  function handleOnFileLoad(fileData) {
    setEdit({
      ...edit,
      fileData: fileData
    })
  }

  function handleOnError(err, file, inputElem, reason) {
    console.log('---------------------------');
    console.log(err);
    console.log('---------------------------');
  }

  function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) === 0) {
      return '0 kB'
    }
    if (Math.abs(bytes) < thresh) {
      return '1 kB';
    }
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  if (submitted)
    return <BatchProcessing
        batchResults={batchResults}
        seconds={seconds}
      />

  return <BatchCreateForm
      edit={edit}
      errorMessages={errorMessages}
      fileInputRef={fileInputRef}
      handleOnError={handleOnError}
      handleOnFileLoad={handleOnFileLoad}
      handleOpenDialog={handleOpenDialog}
      humanFileSize={humanFileSize}
      processing={submitted}
      runBatch={runBatch}
      setEdit={setEdit}
      shipVias={shipVias}
    />
}

export default BatchCreate;