import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../functions/base';

function PrivateRoute ({component: Component, authed, ...rest}) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => auth.token
        ? <Component {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

export default PrivateRoute;