import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';

import { useAuth } from '../functions/base';

function Logout(props) {
  let history = useHistory();
  let auth = useAuth()

  function webServiceLogout() {
    auth.signout()
    history.push('/')
  }

  if (!auth.token || auth.token == null)
    return ''

  return <Button
      floated='right'
      onClick={() => webServiceLogout()}
    >
      Log out
    </Button>
}

export default Logout;