import axios from "axios";
import { config } from "../config";

const authenticationApiUrl = `${config.endpoints.authenticationApiUrl}/api/v1/Authentication`;

export async function login(clientId, username, password, callback, errorCallback) {
  const body = {clientId: clientId, password: password, username: username}
  axios.post(`${authenticationApiUrl}/login`, body)
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}