import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const ratingApiUrl = `${config.endpoints.ratingApiUrl}/api/v1/Rating`;

function formatDate(date) {
  if (!date) {
    return date;
  }
  const splits = date.split('-');
  return `${splits[2]}-${splits[0]}-${splits[1]}T23:00:00.000Z`
}

function getBody(settings) {
  return {
    customerPickTicketNumber: settings.customerPickTicketNumber || "",
    locationId: settings.locationId || "",
    shipDate: formatDate(settings.shipDate),
    shipToAddress: {
      line1: settings.shipToAddress.line1 || "",
      line2: settings.shipToAddress.line2 || "",
      line3: settings.shipToAddress.line3 || "",
      city: settings.shipToAddress.city || "",
      state: settings.shipToAddress.state || "",
      postalCode: settings.shipToAddress.postalCode || "",
      country: settings.shipToAddress.country || "",
      email: settings.shipToAddress.email || "",
      phone: settings.shipToAddress.phone || "",
      name: settings.shipToAddress.name || "",
      company: settings.shipToAddress.company || ""
    },
    billToAddress: {
      line1: settings.billToAddress?.line1 || "",
      line2: settings.billToAddress?.line2 || "",
      line3: settings.billToAddress?.line3 || "",
      city: settings.billToAddress?.city || "",
      state: settings.billToAddress?.state || "",
      postalCode: settings.billToAddress?.postalCode || "",
      country: settings.billToAddress?.country || "",
      email: settings.billToAddress?.email || "",
      phone: settings.billToAddress?.phone || "",
      name: settings.billToAddress?.name || "",
      company: settings.billToAddress?.company || "",
      account: settings.billToAddress?.account || ""
    },
    billingMethod: settings.billingMethod || "",
    packages: settings.packages || [],
    shippingCode: settings.shippingCode || ""
  };
}

export async function rate(token, settings, callback, errorCallback) {
  const body = getBody(settings)
  axios.post(`${ratingApiUrl}/Rate`, body, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}

export async function rateShop(token, settings, callback, errorCallback) {
  const body = getBody(settings)
  axios.post(`${ratingApiUrl}/RateShop`, body, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}