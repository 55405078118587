import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

import AddressContainer from './AddressContainer';
import Branding from './Branding';
import LeftScreenFields from './LeftScreenFields';
import PackagesScreen from './PackagesScreen';
import ShippingAndRatingResults from './ShippingAndRatingResults';
import ShippingButtons from './ShippingButtons';

import { getAccessorialsByCode } from '../api/accessorials-api';
import { getAddressesByLocationId } from '../api/addresses-api';
import { getBoxes } from '../api/boxes-api';
import { printLabel, readScale } from '../api/devices-api';
import { getPickTickets } from '../api/pickTickets-api';
import { getPickTicketById } from '../api/pickTickets-api';
import { rate, rateShop } from '../api/rating-api';
import { getParcelRoutingCodesByLocationId } from '../api/routingCodes-api';
import { getRulesByLocationIdAndType, preShipRules } from '../api/rulesMapper-api';
import { ship } from '../api/shipping-api';

import { convertWeightToPounds, getDate, ignoreAutoComplete, useAuth } from '../functions/base';

function ShippingScreen(props) {
  const [accessorials, setAccessorials] = useState([])
  const [activePackageIndex, setActivePackageIndex] = useState(0)
  const [addresses, setAddresses] = useState([])
  const [boxes, setBoxes] = useState([])
  const [boxId, setBoxId] = useState(null)
  const [businessRulesResults, setBusinessRulesResults] = useState(null)
  const [errorMessages, setErrorMessages] = useState('')
  const [loading, setLoading] = useState(false)
  const [pickTickets, setPickTickets] = useState([])
  const [rateResult, setRateResult] = useState(null)
  const [rateShopResult, setRateShopResult] = useState(null)
  const [rateShopCodes, setRateShopCodes] = useState([])
  const [shipResult, setShipResult] = useState(null)
  const [shipVias, setShipVias] = useState([])
  const [edit, setEdit] = useState({})
  let { id } = useParams()
  let auth = useAuth()
  let history = useHistory()

  function parseShipDate(date) {
    const rawDate = date.substring(0, 10);
    const splits = rawDate.split('-')
    return `${splits[1]}-${splits[2]}-${splits[0]}`
  }

  useEffect(() => {
    const callback = (data) => {
      data.shipDate = parseShipDate(data.shipDate)
      setEdit(data);
    }
    const errorCallback = (error) => {
      history.push('/')
    }
    getPickTicketById(auth.token, id, callback, errorCallback);
  }, [auth.token, history, id])

  useEffect(() => {
    const callback = (data) => {
      setBoxes(data)
    }
    const errorCallback = (error) => {
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }
    getBoxes(auth.token, callback, errorCallback);
  }, [auth.token, id])

  useEffect(() => {
    const callback = (data) => {
      if (!data.shippingCodes)
        return;
      let shipVias = []
      let rateShopCodes = [];
      for (const rawRateShopCode of data.rateShopCodes) {
        const rateShopCode = {
          key: `RS-${rawRateShopCode.code}`,
          text: rawRateShopCode.description || rawRateShopCode.code,
          value: rawRateShopCode.code
        };
        rateShopCodes.push(rawRateShopCode.code);
        shipVias.push(rateShopCode);
      }

      setRateShopCodes(rateShopCodes);

      for (const rawShippingCode of data.shippingCodes) {
        const shippingCode = {
          key: rawShippingCode.code,
          text: rawShippingCode.displayName || rawShippingCode.code,
          value: rawShippingCode.code
        };
        shipVias.push(shippingCode);
      }
      setShipVias(shipVias);
    }
    const errorCallback = (error) => {
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }
    getParcelRoutingCodesByLocationId(auth.token, edit.locationId, callback, errorCallback);
  }, [auth.token, edit.locationId])

  useEffect(() => {
    if (!edit.shippingCode)
      return;
    const callback = (data) => {
      setAccessorials(data)
    }
    const errorCallback = (error) => {
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }
    getAccessorialsByCode(auth.token, edit.shippingCode, callback, errorCallback);
  }, [auth.token, edit])

  useEffect(() => {
    if (!edit.locationId)
      return;
    const callback = (data) => {
      setAddresses(data);
    }
    const errorCallback = (error) => {
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }
    getAddressesByLocationId(auth.token, edit.locationId, callback, errorCallback);
  }, [auth.token, edit.locationId])

  useEffect(() => {
    const callback = (data) => {
      let pickTickets = []
      for (const rawPickTicket of data) {
        const pickTicket = {
          key: rawPickTicket.id,
          text: rawPickTicket.customerPickTicketNumber,
          value: rawPickTicket.id
        };
        pickTickets.push(pickTicket);
      }
      setPickTickets(pickTickets)
    }
    const errorCallback = (error) => {
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }
    getPickTickets(auth.token, callback, errorCallback);
  }, [auth.token, setPickTickets])

  function editBillingMethod(billingMethod) {
    setEdit({
      ...edit,
      billingMethod: billingMethod
    })
  }

  function addPackage() {
    let packageId = 1
    if (edit.packages.length > 0)
      packageId = Math.max.apply(Math, edit.packages.map((p) => { return p.id })) + 1
    const parcel = {
      id: packageId,
      weight: {
        value: 0,
        unit: 'pounds'
      },
      dimensions: {
        unit: 'inches',
        length: 0,
        width: 0,
        height: 0
      },
      accessorials: accessorials
    }
    const packages = [...edit.packages, parcel]
    setEdit({...edit, packages: packages})
  }

  function deletePackage(id) {
    let packages = edit.packages.filter(p => p.id !== id)
    for (let i = 0; i < packages.length; i++) {
      packages[i].id = i + 1;
    }
    setEdit({...edit, packages: packages})
    if (activePackageIndex > packages.length - 1 && packages.length >= 1)
       setActivePackageIndex(packages.length - 1)
  }

  function editBillToAddress(update) {
    setEdit({
      ...edit,
      billToAddress: { ...update },
    })
  }

  function editOriginAddress(newLocationId) {
    setEdit({
      ...edit,
      locationId: newLocationId,
    })
  }

  function editPackageAccessorial(packageId, key, value) {
    let parcel = edit.packages.find(p => p.id === packageId);
    if (!parcel)
      return;
    let packages = []
    if (!['residential', 'billingMethod'].includes(key)) {
      parcel.accessorials[key] = value
      packages = edit.packages.map(p => (p.id === packageId) ? parcel : p)
      setEdit({
        ...edit,
        packages: packages
      })
    } else {
      // residential and billingMethod are shipment level accessorials not package level
      setEdit({
        ...edit,
        [key]: value
      })
    }
  }

  function editPackage(id, update) {
    const newPackages = edit.packages.map((p) => {
      if (p.id === id) {
        const updatedPackage = {
          ...p,
          ...update
        };
        return updatedPackage;
      }
      return p;
    });
    setEdit({
      ...edit,
      packages: newPackages
    });
  }

  function editShippingCode(shippingCode) {
    setEdit({
      ...edit,
      shippingCode: shippingCode
    })
  }

  function editShipToAddress(update) {
    setEdit({
      ...edit,
      shipToAddress: { ...update },
    })
  }

  function setCustomerPickTicketNumber(pickTicketId) {
    clearResults()
    setLoading(false)
    history.push(`/shipping/${pickTicketId}`)
  }

  function clearResults() {
    setBusinessRulesResults(null)
    setLoading(true)
    setRateResult(null)
    setRateShopResult(null)
    setShipResult(null)
    setErrorMessages('')
  }

  function webServiceRate() {
    clearResults()

    function rateCallback(data) {
      data.shippingCode = shipVias.find(s => s.value === edit.shippingCode)?.text
      setRateResult(data);
      setLoading(false);
    }

    function rateErrorCallback(error) {
      setLoading(false);
      setRateResult({
        totalCharge: 'error rating',
        tntDays: 'error rating',
        shippingCode: 'error rating'
      })

      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }

    function rateShopCallback(data) {
      for (const result of data.results) {
        result.key = result.shippingCode
        result.shippingCode = shipVias.find(s => s.value === result.key)?.text
      }
      setRateShopResult(data);
      setLoading(false);
    }

    function rateShopErrorCallback(error) {
      setLoading(false);
      setRateShopResult({
        results: [{
          totalCharge: 'error rate shopping...',
          tntDays: 'error rate shopping...',
          shippingCode: 'error rate shopping...'
        }]
      })
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }

    if (rateShopCodes.includes(edit.shippingCode))
    {
      setRateShopResult({
        results: [{
          totalCharge: 'rate shopping...',
          tntDays: 'rate shopping...',
          shippingCode: 'rate shopping...'
        }]
      })

      rateShop(auth.token, edit, rateShopCallback, rateShopErrorCallback);
    } else {
      setRateResult({
        totalCharge: 'rating...',
        tntDays: 'rating...',
        shippingCode: 'rating...'
      })

      rate(auth.token, edit, rateCallback, rateErrorCallback);
    }
  }

  function webServiceShip() {
    clearResults()

    function callback(data) {
      setLoading(false)
      data.shippingCode = shipVias.find(s => s.value === edit.shippingCode)?.text
      setShipResult(data)
      webServicePrintLabel(data.labels)
    }

    function errorCallback(error) {
      setLoading(false)
      setShipResult({
        totalCharge: 'error shipping',
        tntDays: 'error shipping',
        shippingCode: 'error shipping',
        trackingNumber: 'error shipping...'
      })
      if (error.response)
        setErrorMessages(error.response.data.message)
      else
        setErrorMessages(error.message)
    }

    setShipResult({
      totalCharge: 'shipping...',
      tntDays: 'shipping...',
      shippingCode: 'shipping...',
      trackingNumber: 'shipping...'
    })

    ship(auth.token, edit, callback, errorCallback);
  }

  function webServiceBusinessRules() {
    clearResults()

    function callbackGetRules(data) {
      function callback(data) {
        setLoading(false)
        setBusinessRulesResults({message: 'done running busines rules'})
        data.shipDate = parseShipDate(data.shipDate)
        setEdit(data)
      }

      function errorCallback(error) {
        setLoading(false)
        if (error.response.data.message)
          setBusinessRulesResults({message: `error running busines rules: ${error.response.data.message}`})
        else
          setBusinessRulesResults({message: `error running busines rules: ${error}`})
      }
      const rules = JSON.parse(data)
      preShipRules(auth.token, edit, rules, callback, errorCallback)
    }

    function errorCallbackGetRules(error) {
      setLoading(false);
      setBusinessRulesResults({message: `error running busines rules: ${error}`})
    }

    setBusinessRulesResults({message: 'running business rules...'})
    
    getRulesByLocationIdAndType(auth.token, edit.locationId, 'preship', callbackGetRules, errorCallbackGetRules);
  }

  function webServicePrintLabel(labels) {
    function callback(data) {
      setLoading(false);
      console.log(data)
    }

    function errorCallback(error) {
      setLoading(false);
      if (error.message && error.message === 'Network Error') {
        setErrorMessages(['There was an error printing the label. Could not connect to the ShipVia tray application. Please make sure it is running'])
      } else {
        const data = error.response.data
        console.log(data)
      }
    }

    for (const label of labels) {
      const payload = {
        printerName: 'ZDesigner gc420d',
        zpl: label,
        version: "1"
      }
  
      printLabel("https://localhost:44357/api/v1/Printer/PrintLabel", payload, callback, errorCallback)
    }
  }

  function webServiceReadScale() {
    clearResults()

    function callback(data) {
      setLoading(false);
      const weight = convertWeightToPounds(data, [])
      const parcel = edit.packages[activePackageIndex]
      const updatedWeight = { ...parcel.weight, value: weight };
      editPackage(parcel.id, { ...parcel, weight: updatedWeight });
    }

    function errorCallback(error) {
      setLoading(false);
      if (error.message && error.message === 'Network Error') {
        setErrorMessages(['Could not connect to the ShipVia tray application. Please make sure it is running'])
      } else {
        const message = error.response.data.message
        let errorMessage = ''
        if (message === "COULD_NOT_FIND_DEVICE" || message === "COULD_NOT_CONNECT")
          errorMessage = 'Please make sure your scale is running and that the ShipVia tray application is running'
        else if (message === "COULD_NOT_READ")
          errorMessage = 'Could not read the scale, please try again'
        else
          errorMessage = `${message}, please contact support`;
        setErrorMessages([errorMessage])
      }
    }

    const payload = {
      connectTries: 10,
      productId: 0,
      readTimeout: 10,
      sleepTimeBetweenConnectionTry: 50,
      vendorId: 2338,
      version: "1"
    }

    readScale("https://localhost:44357/api/v1/Scale/Read", payload, callback, errorCallback)
  }

  if (!edit.id)
    return <span>Pick ticket not found</span>
  
  return <Form>
    <Grid padded>
      <Grid.Row>
        <Grid.Column style={{ flex: 1, flexBasis: 0 }} width={4}>
          <Segment>
            <LeftScreenFields
              accessorials={accessorials}
              customerPickTicketNumber={edit.id}
              editBillingMethod={editBillingMethod}
              editShippingCode={editShippingCode}
              pickTickets={pickTickets}
              setCustomerPickTicketNumber={setCustomerPickTicketNumber}
              shipDate={edit.shipDate}
              shippingCode={edit.shippingCode}
              shipVias={shipVias}
            >
              <PackagesScreen
                activePackageIndex={activePackageIndex}
                addPackage={addPackage}
                allAccessorials={accessorials}
                billingMethod={edit.billingMethod}
                boxes={boxes}
                boxId={boxId}
                deletePackage={deletePackage}
                editPackage={editPackage}
                editPackageAccessorial={editPackageAccessorial}
                packages={edit.packages}
                residential={edit.residential}
                setActivePackageIndex={setActivePackageIndex}
                setBoxId={setBoxId}
              />
            </LeftScreenFields>
            <Branding />
          </Segment>
        </Grid.Column>
        <Grid.Column style={{ flex: 1, flexBasis: 0 }} width={4}>
          <Segment>
            <Form.Group>
              <Form.Dropdown
                fluid
                label='Shipping Service'
                onChange={(e, data) => editShippingCode(data.value)}
                onFocus={ignoreAutoComplete}
                options={shipVias}
                placeholder='Shipping Service'
                search
                selection
                value={edit.shippingCode}
                width='sixteen'
              />
              <DateInput
                animation=''
                closable={true}
                dateFormat="MM-DD-YYYY"
                label='Shipping Date'
                minDate={getDate()}
                onChange={(e, data) => setEdit({ ...edit, shipDate: data.value })}
                popupPosition="top right"
                value={edit.shipDate}
                width='six'
              />
            </Form.Group>
            <ShippingAndRatingResults
              businessRulesResults={businessRulesResults}
              editShippingCode={editShippingCode}
              errorMessages={errorMessages}
              rateResult={rateResult}
              rateShopResult={rateShopResult}
              shippingCode={edit.shippingCode}
              shipResult={shipResult}
            />
            <ShippingButtons
              loading={loading}
              webServiceRate={webServiceRate}
              webServiceShip={webServiceShip}
              webServiceBusinessRules={webServiceBusinessRules}
              webServiceReadScale={webServiceReadScale}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column style={{ flex: 1, flexBasis: 0 }} width={4}>
          <Segment>
            <AddressContainer
              addresses={addresses}
              billToAddress={edit.billToAddress}
              editBillToAddress={editBillToAddress}
              editOriginAddress={editOriginAddress}
              editShipToAddress={editShipToAddress}
              locationId={edit.locationId}
              shipToAddress={edit.shipToAddress}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
}

export default ShippingScreen;