import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuctionBid from './components/AuctionBid';

import BatchCreate from './components/BatchCreate';
import Heading from './components/Heading';
import Home from './components/Home';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import ShippingScreen from './components/ShippingScreen';

function App() {
  return <div>
      <Heading />
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/shipping/:id" component={ShippingScreen} />
        <PrivateRoute path="/batch" component={BatchCreate} />
        <Route path="/auction-bid/:hash" component={AuctionBid} />
      </Switch>
    </div>
}

export default App;
