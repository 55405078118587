import React from 'react';

import allThatShipLogo from '../images/AllThatShip.png';
import poweredByShipViaLogo from '../images/PoweredByShipVia.png';

function Branding(props) {
  return <>
    <img src={allThatShipLogo} alt="All That Ship logo" />
    <img src={poweredByShipViaLogo} alt="Powered By ShipVia logo" />
  </>
}

export default Branding;