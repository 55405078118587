import React from 'react';
import { authContext, useProvideAuth } from '../functions/base';

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default ProvideAuth;