import { createContext, useContext, useEffect, useState } from "react";

const authContext = createContext();

function getDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  return `${mm}-${dd}-${today.getFullYear()}`;
}

function ignoreAutoComplete(event) {
  event.target.setAttribute('autocomplete', null);
}

function useAuth() {
  return useContext(authContext);
}

const useLocalStorage = key => {
  const [value, setValue] = useState(
    localStorage.getItem(key) || ''
  )

  useEffect(() => {
    if (value == null || !value) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, value);
    }
  }, [key, value])

  return [value, setValue]
}

function useProvideAuth() {
  const [token, setToken] = useLocalStorage('token');

  const signin = token => {
    setToken(token)
  };

  const signout = callback => {
    setToken(null)
  };

  return {
    token,
    signin,
    signout
  };
}

function convertWeightToPounds(readScaleResult, conversions) {
  const conversion = conversions.find(c => c.productId === readScaleResult.productId && c.vendorId === readScaleResult.vendorId)
  if (!conversion) {
    const base = (readScaleResult.weightLsb + readScaleResult.weightMsb * 256)
    if (readScaleResult.weightUnit === 11)
      return (base / 10) * 0.0625 // ounces to pounds
    else if (readScaleResult.weightUnit === 2)
      return (base / 1000) * 2.20462 // kilograms to pounds
  }
}

export { authContext, convertWeightToPounds, getDate, ignoreAutoComplete, useAuth, useLocalStorage, useProvideAuth }