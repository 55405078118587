import React from 'react';
import { Grid } from 'semantic-ui-react';

import Logout from './Logout';
import TargetedMessages from './TargetedMessages';

function Heading(props) {
  const messages = [
    // {level: 'error', text: 'FedEx is having issues, we are working with them to get this resolved'},
    // {level: 'warning', text: 'UPS appears to be resolved. Please contact support if there is still an issue'},
    // {level: 'info', text: 'ShipVia is the best shipping application'},
  ]

  return <Grid padded>
      <Grid.Row>
        <Grid.Column width='two'>
          &nbsp;
        </Grid.Column>
        <Grid.Column width='twelve'>
          <TargetedMessages messages={messages} />
        </Grid.Column>
        <Grid.Column width='two'>
          <Logout />
        </Grid.Column>
      </Grid.Row>
    </Grid>
}

export default Heading;