import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const locationsApiUrl = `${config.endpoints.locationsApiUrl}/api/v1/Locations`;

export async function getAllClientLocations(token, callback, errorCallback) {
  axios.get(locationsApiUrl, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}