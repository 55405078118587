import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const truckLoadAuctionApiUrl = `${config.endpoints.truckLoadAuctionApiUrl}`;

export async function getAuctionBidEmailByHash(token, hash, callback, errorCallback) {
  if (hash) {
    console.log(`${truckLoadAuctionApiUrl}/api/v1/AuctionBidEmail/ByHash/${hash}`)
    axios.get(`${truckLoadAuctionApiUrl}/api/v1/AuctionBidEmail/ByHash/${hash}`, getConfig(token))
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      errorCallback(err)
    })
  } else {
    callback({})
  }
}

export async function updateAuctionBid(token, auctionId, hash, body, callback, errorCallback) {
  console.log(body)
  axios.put(`${truckLoadAuctionApiUrl}/api/v1/Auction/${auctionId}/AuctionBid/ByHash/${hash}`, body, getConfig(token))
  .then(response => {
    callback(response.data);
  })
  .catch(err => {
    errorCallback(err)
  })
}