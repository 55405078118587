import axios from "axios";
import { config } from "../config";
import { getConfig } from './functions';

const rulesMapperApiUrl = `${config.endpoints.rulesMapperApiUrl}/api/v1/RulesMapper`;

export async function getRulesByLocationIdAndType(token, locationId, type, callback, errorCallback) {
  if (locationId && type) {
    axios.get(`${rulesMapperApiUrl}/ByLocationIdAndType/${locationId}/${type}`, getConfig(token))
    .then(response => {
      callback(response.data);
    })
    .catch(err => {
      errorCallback(err)
    })
  } else {
    callback([])
  }
}

export async function preShipRules(token, pickTicket, rules, callback, errorCallback) {
    if (pickTicket && rules) {
      const body = {
        pickTicket: pickTicket,
        businessRules: rules
      }
      axios.post(`${rulesMapperApiUrl}/Preship`, body, getConfig(token))
      .then(response => {
        callback(response.data);
      })
      .catch(err => {
        errorCallback(err)
      })
    } else {
      callback([])
    }
  }